<template>
  <div>
    <v-card class="mt-n6 mb-n3 elevation-0 rounded-0">
      <v-card-title class="px-1 px-sm-3">
        <button-tip xsmall fab icon icolor="black" iname="mdi-update"
                    elevation="5" tip="Refresh Claims" @click="getMembersForThisFirm(firm.fid)"/>
        <v-spacer/>
        <v-switch color="secondary"
                  dense
                  label="Surname Sort"
                  v-model="sortSwitchedToSurname"
                  x-small/>
        <v-spacer/>
        <v-text-field append-icon="mdi-magnify" class="my-n3 mr-2" clearable dense hide-details label="Search"
                      single-line style="max-width: calc(65vw - 150px)" v-model="search"/>
        <button-tip @click="createNewMember(firm.fid)" color="secondary" elevation="5" fab icolor="white"
                    iname="mdi-plus" tip="Create Member" v-if="$_can('person-edit')" xsmall/>
      </v-card-title>
    </v-card>
    <v-data-table class="pb-4 mb-2 elevation-2  med-table-rows pad-last-table-row"
                  :class="[!notPopup ? 'popupMemberTable' : 'memberTable']"
                  @click:row="rowClick"
                  dense
                  fixed-header
                  :headers="colHeaders"
                  hide-default-footer
                  item-key="plid"
                  :items="members"
                  :items-per-page="-1"
                  :loading="loading"
                  loading-text="Data loading... Please wait..."
                  :mobile-breakpoint="0"
                  multi-sort
                  :search="search"
                  single-select
                  :sort-by="sortSelected"
                  :style="$_cssVars" >
      <template v-slot:item.actions="{ item }">
        <button-tip @click="$_editPerson(item)" icon iname="mdi-open-in-new" tip="Member Popup" xsmall/>
        <button-tip @click="confirmDeleteMember(item)" icolor="red lighten-2" icon
                    iname="mdi-trash-can-outline" tip="Delete Member"
                    v-if="$_can('person-override')" xsmall/>
      </template>
      <template v-for="field in fields" v-slot:[`item.${field}`]="{ item }">
        <span v-if="item[field]">
          <div v-if="(phones.includes(field))">{{ item[field].replace(/(\d{3})(\d{3})(\d{4})/,'($1) $2-$3') }}</div>
          <div v-else-if="field === 'ssn'">{{ item[field].replace(/(\d{3})(\d{2})(\d{4})/,'$1-$2-$3') }}</div>
          <v-tooltip left v-else-if="field === 'name' && item[field].length > 40 && (item.suffix || item.salutation)">
            <template v-slot:activator="{ on }"><!-- don't slice off of htmlName because it has embedded tags -->
              <div v-on="on" v-html="item.htmlName + item.postname.slice(0, Math.max(0, 40 - item.htmlName.length)) + '...'"/>
            </template>
            <div v-html="item.htmlName + item.postname"></div>
          </v-tooltip>
          <v-tooltip left v-else-if="field === 'email' && item[field].length > 34">
            <template v-slot:activator="{ on }"><div v-on="on">{{ item[field].slice(0, 29) + '...' }}</div></template>
            {{ item[field] || '' }}
          </v-tooltip>
          <div v-else-if="field === 'name'" v-html="item.htmlName + item.postname"></div>
          <div v-else>{{ item[field] || '' }}</div>
        </span>
      </template>
    </v-data-table>

    <!--  Dialogs  -->
    <ActionDialog :value="confirmDeleteDialog" :max-width="700" divider confirm-text="Delete Member"
                  title="Delete Member" close-text="Cancel" confirm-color="error"
                  @confirm="deleteMember" @close="confirmDeleteDialog = false">
      <v-alert class="mt-6" border="bottom" colored-border type="warning" elevation="2">
        Are you sure you want to <strong>delete</strong> this member?
        <br><br>sort: {{memberToDelete.sort}}<br><br>
        This deletes it from the database, with no way to recover. Click "DELETE" below to proceed with deletion.
      </v-alert>
    </ActionDialog>
    <ErrorDialog ref="errorDialog"/>
    <snack :color="$_snack.color" :msg="$_snack.msg" v-model="$_snack.open" v-if="$_snack.cmpnt==='FirmMembersTable'"/>

  </div>
</template>

<script>
  import ActionDialog from "../../ActionDialog";
  import { PeopleAPIService } from "@/servicehandlers/PeopleAPIService";
  import { FirmsAPIService } from "@/servicehandlers/FirmsAPIService";

  const peopleAPIService = new PeopleAPIService();
  const firmsAPIService = new FirmsAPIService();

  export default {
    name: "FirmMembersTable",
    components: {ActionDialog},
    props: {
      firm: Object,
      notPopup: Boolean,
      default() {
        return {};
      }
    },
    data() {
      return {
        colHeaders: [
          {value: 'actions', text: '',        width:  "75px",sortable: false,align:'center'},//popup for view or edit
          {value: 'name',    text: 'Name',    width: "325px",sortable: true, align: 'start'},
          {value: 'role',    text: 'Role',    width:  "75px",sortable: true, align: 'start'},
          {value: 'mobile',  text: 'Mobile',  width: "130px",sortable: true, align: 'start'},
          {value: 'home',    text: 'Home',    width: "130px",sortable: true, align: 'start'},
          {value: 'direct',  text: 'Direct',  width: "130px",sortable: true, align: 'start'},
          {value: 'ext',     text: 'Ext',     width:  "75px",sortable: true, align: 'start'},
          {value: 'fax',     text: 'Fax',     width: "130px",sortable: true, align: 'start'},
          {value: 'email',   text: 'Email',   width: "280px",sortable: true, align: 'start'},
          {value: 'licensed',text: 'Licensed',width: "100px",sortable: true, align: 'start'},
          {value: 'ce',      text: 'CE',      width:  "75px",sortable: true, align: 'start'},
          {value: 'hired',   text: 'Hired',   width: "100px",sortable: true, align: 'start'},
          {value: 'hours',   text: 'Hours',   width:  "85px",sortable: true, align: 'start'},
        ],
        confirmDeleteDialog: false,
        errors: [],
        loading:false,
        members: [],
        memberToDelete: {},
        names: ['prefix','given','surname','suffix','salutation'],
        phones: ['direct','fax','home','mobile'],
        search: '',
        sortSelected: ['name'],// default sort by name column
        sortSwitchedToSurname: false,
      }
    },

    created () {
      this.getMembersForThisFirm(this.firm.fid);
    },

    computed: {
      fields() {
        return this.colHeaders.map(a => a.value === 'actions' ? '' : a.value);//fields are all colHeaders except actions
      },
    },

    watch: {
      sortSwitchedToSurname() {
        this.sortSelected = this.sortSwitchedToSurname ? ['surname', 'given'] : ['name'];
        this.getMembersForThisFirm(this.firm.fid);// refresh member table to show new sort order
      }
    },

    methods: {
      confirmDeleteMember(member) {
        this.memberToDelete = member;
        this.confirmDeleteDialog = true;
      },

      deleteMember() {
        peopleAPIService.deletePerson(this.memberToDelete.plid, this.$router).then((res) => {
          this.confirmDeleteDialog = false;
          this.getMembersForThisFirm(this.firm.fid);// refresh members table to show member is gone
          this.$store.dispatch('SET_SNACK', {cmpnt: 'FirmMembersTable', open: true, msg: 'Member deleted'});
        }).catch((e) => {
          this.confirmDeleteDialog = false;
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to delete member.",
            forDevelopers: e
          })
        });
      },

      createNewMember(fid) {
        const params = {fid: fid};// initial param for new member
        peopleAPIService.createPerson(params, this.$router).then((member) => {
          this.getMembersForThisFirm(this.firm.fid);
          this.$_editPerson(member);
          }).catch((e) => {
            this.$refs.errorDialog.showError({
              errorMessage: "Failed to create new member.",
              forDevelopers: e
            })
          });
      },

      getMembersForThisFirm(id) {
        let FID = id.toString();
        firmsAPIService.getFirmPeople(FID, this.$router).then((data) => {
          if (data) {
            this.members = data;
            for (let p of this.members) {// for people (p) in members trim space & format fields to improve sort/display
              for (let fld in p) if (p[fld] && this.names.includes(fld)) p[fld] = p[fld].trim().replace(/�/g, "'");
              p.suffix = p.suffix ? (p.suffix.substring(0, 2) === ', ' ? p.suffix : ', ' + p.suffix) : '';//no comma, add
              // if there's a prefix & a salutation that doesn't include the prefix, add prefix & surname to salutation
              if (p.prefix && p.salutation && !p.salutation.includes(p.prefix)) p.salutation = `${p.salutation} or ${p.prefix} ${p.surname}`;
              // if there's a prefix and no salutation, use prefix + surname as salutation
              if ((p.prefix && !p.salutation)) p.salutation = `${p.prefix} ${p.surname}`;
              if (p.salutation) p.salutation = `(${p.salutation})`;// now add parenthesis if there is a salutation
              p.name = `${p.given || ''} ${p.surname || ''}${p.suffix} ${p.salutation}`;//set name column
              p.postname = `${p.suffix} ${p.salutation}`;//part that could be wholly/partially substituted with ellipsis
              p.htmlName = `${p.given || ''}<b> ${p.surname}</b>`;//part that can't be shortened because of bold tags
            }
          }
        }).catch((e) => {
          this.$refs.errorDialog.showError({
            errorMessage: "Failed to get members for this firm",
            forDevelopers: e
          })
        });
      },

      rowClick: function (item, row) {// marks which claim was just opened in a popup window
        row.select(true);
      },
  },

}
</script>

<style>
   /* custom member table heights */
  .memberTable .v-data-table__wrapper{height:calc(100vh - var(--offset) - 158px) !important}
  .popupMemberTable .v-data-table__wrapper{height:calc(100vh - var(--offset) - 95px) !important}
</style>
<template>
  <div v-if="$parent.showFirmTabs || (showFirmTabs && !notPopup)" class="px-1 pb-1 pt-6">
    <v-tabs dark show-arrows v-model="tab" background-color="primary" slider-size="2"
            style="border-top: 2px solid white !important;"
            :height="notPopup ? '34px' : '0px'" :class="notPopup ? 'pt-0 mt-0' : 'my-n6'">
      <v-tab ripple v-show="notPopup" class="elevation-4"
             v-for="firm in firmArray" :key="firm.fid">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
              <span class="justify-space-between">
                <v-row style="margin: 1px; white-space: nowrap; text-transform: lowercase" v-on="on"
                       class="d-flex justify-space-between ml-0 mb-n1"
                       @click="updateTabsBelow(firm)" @dblclick="$parent.firmPopup(firm.fid)">
                  <span>{{ firm.fid }}
                    <span class="pa-0 caption" style="text-align: start">
                      {{firm.producer}}/{{firm.solicitor}}/{{firm.agent}}
                    </span>
                  </span>
                  <span @click.stop="closeTab(firm.fid)" class="pa-0 my-n6 d-flex justify-end"
                        style="background-color: transparent">
                    <v-icon small class="pa-0 my-0 mr-3" style="justify-content: end">mdi-close</v-icon>
                  </span>
                </v-row>
              <span>{{ firm.sort.substring(0, ( 60 / (firmArray.length + 1.4) + 9 ) )}}</span>
            </span>
          </template>
          <span>
            Single-click to select Firm, double-click high on the button to open in popup, click X to delete tab.
          </span>
        </v-tooltip>
      </v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="firm in firmArray" :key="firm.fid">
          <v-tabs show-arrows v-model="tables" slider-color="secondary" height="35"
                  :hide-slider="hideSlider" slider-size="4" class="mt-n1 py-0 mx-0">
              <v-tab v-for="(table, i) in tableItems" :key="i" class="elevation-4">
                {{ table }}
                <span v-if="table === 'Info'" v-can="'firm-edit'">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                          style="transform: scale(.8)"
                          fab x-small class="ml-1 mr-n3" dark color="secondary"
                          @click.stop="$_editFirm(firm)" v-on="on">
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit Firm Popup</span>
                  </v-tooltip>
                </span>
                <span v-if="notPopup && table === 'Notes'">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn  :loading="$parent.loadingJob"
                              style="transform: scale(.8)"
                              fab x-small class="ml-2 mr-n3" dark color="secondary"
                              @click.stop="nextNoteJob" v-on="on">
                        <v-icon>mdi-transfer</v-icon>
                      </v-btn>
                    </template>
                    <span>Next Job</span>
                  </v-tooltip>
                </span>
              </v-tab>
          </v-tabs>
          <v-tabs-items touchless v-model="tables">
            <v-tab-item v-for="(table, i) in tableItems" :key="i">
              <firm-info v-if="table === 'Info'" v-bind="{notPopup, firmProp: firm, rightsArray}"></firm-info>
              <firm-notes-table v-if="table === 'Notes'" v-bind="{firm, firmArray, key: note.nid,
                          noNote, notPopup, rightsArray, updateNoteTableHeight, nextJobFidProp: note.fid,
                          nextJobNidProp: note.nid, statusProp: status}"/>
              <firm-members-table v-if="table === 'Members'" v-bind="{notPopup, firm, rightsArray}"/>
              <firm-claims-table v-if="table === 'Claims'" v-bind="{notPopup, firm, rightsArray}"/>
              <firm-policies-table v-if="table==='Policies'" v-bind="{notPopup, firm,rightsArray}"/>
              <firm-files-table v-if="table === 'Files'" v-bind="{notPopup, firm}"/>
            </v-tab-item>
          </v-tabs-items>

        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
  import ButtonTip from "../../ButtonTip";
  import FirmInfo from "@/components/search/firms/FirmInfo";
  import loginCheckMixin from '@/mixin';
  import FirmMembersTable from "./FirmMembersTable";
  import FirmNotesTable from "./FirmNotesTable";
  import FirmFilesTable from "./FirmFilesTable";
  import FirmPoliciesTable from "./FirmPoliciesTable";
  import FirmClaimsTable from "./FirmClaimsTable";
  import { FirmsAPIService } from '@/servicehandlers/FirmsAPIService';
  import { NotesAPIService } from '@/servicehandlers/NotesAPIService';

  const notesAPIService = new NotesAPIService();
  const apiService = new FirmsAPIService();

  export default {
    name: 'ViewFirm',
    mixins: [loginCheckMixin],
    props: {
      id: [String, Number],
      noNote: Boolean,
      notPopup: Boolean,
      showFirm: Boolean,
    },
    components: {ButtonTip, FirmInfo, FirmMembersTable, FirmNotesTable, FirmFilesTable, FirmPoliciesTable, FirmClaimsTable},
    data: function () {
      return {
        currentActiveFirm: {},
        errors: [],
        firm: {},
        firmArray: [],
        hideSlider: false,
        infoDialog: false,
        newActiveFirm: {},
        note: {},
        noteData: undefined,
        rightsArray: [],
        showFirmTabs: true,
        status: 'All',
        tab: 0,
        tables: 1,
        // tableItems: [ 'Info', 'Notes', 'Policies' ],
        // tableItems: [ 'Info', 'Notes', 'Policies', 'Claims', 'Files'],
        tableItems: [ 'Info', 'Notes', 'Policies', 'Members', 'Claims', 'Files'],
        tabWasClosed: false,
        updateNoteTableHeight: false,
        y: 0,
      };
    },

    watch: {
      tab () {
        if (this.tab === -1) return;
        let firm = this.tab === undefined ? this.newActiveFirm : this.firmArray[this.tab];
        document.title = `${firm.fid}  ${firm.sort} - ${firm.producer}/${firm.solicitor}/${firm.agent}`;
        if (this.tables === 1) this.updateNoteTableHeight = !this.updateNoteTableHeight;
        this.updateSlider();
      },

      showFirm () {
        this.getFirmData();
      },

      tables () {
        if (this.tables === 1) this.updateNoteTableHeight = !this.updateNoteTableHeight;
        this.$parent.panels = [];
      },
    },

    created() {
      this.rightsArray = this.loginCheckMixin(['firm-view', 'firm-edit', 'note-edit', 'person-edit',
        'claim-edit' ]);
      this.getFirmData();// use id to get note, firm, firmArray, tab
    },

    updated() {
      const interval = setTimeout(() => {
        if (this.tabWasClosed) {// if tab was closed, reset tab if needed
          let tab = this.firmArray.findIndex(x => x.fid === this.newActiveFirm.fid);
          if (this.tab !== tab) {
            this.tab = tab;
          } else this.tabWasClosed = false;
        }
      }, 300)
    },

    methods: {
      closeTab(firmId) {
        this.currentActiveFirm = this.firmArray[this.tab];
        let removeIndex = this.firmArray.map(function(item) { return item.fid; }).indexOf(firmId);
        // if closing inactive tab, keep active tab, else activate left tab unless there is none, then right tab
        let replaceIndex = removeIndex === 0 ? 0 : removeIndex - 1;
        this.newActiveFirm = firmId === this.firm.fid ? this.firmArray[replaceIndex] : this.currentActiveFirm;
        // remove current firm object from firmArray tabs
        this.firmArray.splice(removeIndex, 1);
        this.tabWasClosed = true;
        if (this.firmArray.length === 0) {
          this.$parent.showFirmTabs = false;
          document.title = 'Firms';
          this.$parent.panels = [0];
        }
      },

      async getFirmData () {//get firm and build firmArray
        this.status = 'All';
        let fid = this.id;//fid for getSingleFirm
        if (!this.noNote) {// id passed was for a note, not firm, so call api to get note first
          let data = await notesAPIService.getNote(this.id, this.$router);
          if (data) {
            this.note = data;
            this.status = 'Active';
            this.noteData = this.$store.getters.noteData;
            this.noteData.nextJobNid = this.note.nid;
            this.noteData.nextJobFid = this.note.fid;
            fid = this.note.fid; //if id passed is for note, then reset fid for getSingle firm
          }
        } else {}
        let firm = await apiService.getSingleFirm(fid, this.$router);
        this.firm = firm;
        this.firm.nid = this.noNote ? null : this.note.nid;// used to set search in notesTable
        document.title = `${firm.fid}  ${firm.sort} - ${firm.producer}/${firm.solicitor}${firm.agent ? '/' + firm.agent : ''}`;
        let duplicate = this.firmArray.findIndex(x => x.fid === fid);
        duplicate !== -1 ? '' : this.firmArray.push(this.firm);
        // this.firmArray.sort((a, b) => a.id - b.id);
        let index = this.firmArray.map(function(item) {return item.fid; }).indexOf(this.firm.fid);
        this.tab = index;
        this.$parent.loadingJob = false;
        this.noteData = this.$store.getters.noteData;//get all the noteData from the store
        this.noteData.firmArray = this.firmArray;
        this.$store.dispatch('SET_NOTES_DATA', this.noteData);
        this.$parent.showFirmTabs = true;
        this.showFirmTabs = true;
      },

      async nextNoteJob () {
        await this.$parent.nextNote();
        const interval = setTimeout(() => {
          this.tables = undefined;
          this.tables = 1;
          this.hideSlider = false;
        }, 1500)
      },

      updateSlider() {
        this.hideSlider = true;// toggle to force slider to update to stay visible
        const interval = setTimeout(() => {
          this.hideSlider = false;// toggle to force slider to update to stay visible
          if (this.tables === 0 ) {// case where tables won't update properly
            const interval = setTimeout(() => {
              this.tables = 0;
            }, 20)
          }
        }, 20)
      },

      updateTabsBelow(firm) {
        this.firm = firm;
        this.$parent.seeMoreOptions = false;
        this.$parent.panels = [];
        this.updateSlider();
      },

    },

  };
</script>
<style>

  .v-data-table {
    max-width: 100%;
  }

  .theme--dark.v-tabs > .v-tabs-bar {
    z-index: 59;
  }

  .theme--light.v-input:not(.v-input--is-disabled) input, .theme--light.v-input:not(.v-input--is-disabled) textarea {
    color: rgba(0, 0, 0, 0.87);
  }

  button, input, optgroup, select, textarea {
    cursor: pointer;
  }

  .noUnderline.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
  }
  .noUnderline.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
  }

  .theme-dominionBlue .vdatetime-popup__header,
  .theme-dominionBlue .vdatetime-calendar__month__day--selected > span > span,
  .theme-dominionBlue .vdatetime-calendar__month__day--selected:hover > span > span {
    background: #022640;
  }
  .theme-dominionBlue .vdatetime-year-picker__item--selected,
  .theme-dominionBlue .vdatetime-time-picker__item--selected,
  .theme-dominionBlue .vdatetime-popup__actions__button {
    color: #022640;
  }
  .v-small-dialog__content {
    padding: 0;
    margin-bottom: -15px;
  }

  /*ckeditor Textbox*/

  .ck-content .table table {
    width: auto !important;
    margin: 0 auto;
  }
  .ck.ck-editor__editable_inline {
    padding: 0 1.5vh 1vh 1.5vh;
    margin: auto;
    /*margin: 0 auto 0 auto;*/
  }
  .ck-editor__editable {
    overflow-y: scroll;
    min-height: 150px;
    max-height: 50vh;
    min-width: 260px;
    margin: 0;
  }
  /*css below is to solve double space problem when hitting enter*/
  .ck-editor__editable > p {
    margin: 0;
  }
  /*Toolbar*/
  .ck-editor__top {
    z-index: 1;
    min-width: 260px;
  }

  .theme--light.v-data-table thead tr:last-child th {
    z-index: 9
  }
</style>

<template>
  <div>
<!--    need separate component if there's a v-mask, because :v-mask must be on field with v-model -->
    <component
      :attach="attach"
      :autofocus="autofocus"
      @blur="focused = false"
      :chips="chips"
      :clearable="clearable && !readonly"
      @click:append="appendClick"
      @click:append-outer="appendOuterClick"
      @click:clear="clear()"
      @click:prepend="$emit('click-prepend')"
      @click="raiseClickEvent()"
      :color="color"
      :custom-select="customSelect"
      :custom-select-field="customSelectField"
      :deletable-chips="deletableChips"
      :dense="dense"
      :disabled="disabled"
      @focus="focused = true"
      :hint="hint"
      @input="$emit('input', $event === '' && allowStr ? null : $event)"
      :is="type"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      @keydown.enter="enterPress"
      :label="label"
      :menu-props="menuProps"
      :multiple="multiple"
      :no-data-text="noDataText"
      :persistent-hint="persistentHint"
      :prepend-icon="prependIcon"
      :readonly="readonly"
      ref="field"
      :reg-x="regX"
      :return-object="returnObject"
      :reverse="reverse"
      :rows="rows"
      :rules="rules"
      :small-chips="smallChips"
      :tabindex="tabindex"
      :value="value"
      v-bind="{...inputProps, appendIcon, appendOuterIcon}"
      v-if="maskExists"
      v-mask="mask"
    >
      <template v-slot:prepend v-if="!!prependIcon">
        <span style="margin-top: -6px">
          <button-tip btnclass="ml-n4 mr-n3" @click="$emit('click-prepend')" icon :iname="prependIcon"
                      :tip="prependTip"/>
        </span>
      </template>
      <template v-slot:append-outer v-if="dirtyCheck && dirty && revertTip">
        <span style="margin: -6px -3px -6px -1px">
          <button-tip btnclass="ml-n4 mr-n3" @click="appendOuterClick()" icon iname="mdi-arrow-u-left-top"
                      tip="Revert Value"/>
        </span>
      </template>
    </component>
    <component
      :attach="attach"
      :autofocus="autofocus"
      @blur="focused = false"
      :chips="chips"
      :clearable="clearable && !readonly"
      @click:append="appendClick"
      @click:append-outer="appendOuterClick"
      @click:clear="clear()"
      @click:prepend="$emit('click-prepend')"
      @click="raiseClickEvent()"
      :color="color"
      :custom-select="customSelect"
      :custom-select-field="customSelectField"
      :deletable-chips="deletableChips"
      :dense="dense"
      :disabled="disabled"
      @focus="focused = true"
      :hint="hint"
      @input="$emit('input', $event === '' && allowStr ? null : $event)"
      :is="type"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      @keydown.enter="enterPress"
      :label="label"
      :menu-props="menuProps"
      :multiple="multiple"
      :no-data-text="noDataText"
      :persistent-hint="persistentHint"
      :prepend-icon="prependIcon"
      :readonly="readonly"
      ref="field"
      :reg-x="regX"
      :return-object="returnObject"
      :reverse="reverse"
      :rows="rows"
      :rules="rules"
      :small-chips="smallChips"
      :tabindex="tabindex"
      :value="value"
      v-bind="{...inputProps, appendIcon, appendOuterIcon}"
      v-else
    >
      <template v-slot:prepend v-if="!!prependIcon">
        <span style="margin-top: -6px">
          <button-tip btnclass="ml-n4 mr-n3" @click="$emit('click-prepend')" icon :iname="prependIcon"
                      :tip="prependTip"/>
        </span>
      </template>
      <template v-slot:append-outer v-if="dirtyCheck && dirty && revertTip">
        <span style="margin: -6px -3px -6px -1px">
          <button-tip btnclass="ml-n4 mr-n3" @click="appendOuterClick()" icon iname="mdi-arrow-u-left-top"
                      tip="Revert Value"/>
        </span>
      </template>
      <template v-if="customSelect" v-slot:item="{ item }">
        <v-tooltip :nudge-left="35" right>
          <template v-slot:activator="{ on }">
            <v-list-item-title v-on="on">{{ item.value }}</v-list-item-title>
          </template>
          {{ item[customSelectField] }}
        </v-tooltip>
      </template>
    </component>
  </div>
</template>

<script>
  import { VAutocomplete, VCombobox, VSelect, VTextarea, VTextField } from "vuetify/lib"

  export default {
    name: "SmartInput",
    components: { VAutocomplete, VCombobox, VSelect, VTextarea, VTextField },
    props: {
      allowStr: { type: Boolean, default: false },// set to true if str val shouldn't trigger dirty check (allow "1"=1)
      attach: { type: Boolean, default: false },
      autofocus: { type: Boolean, default: false },
      chips: { type: Boolean, default: false },
      cleanValue: { default: null },
      clearable: { type: Boolean, default: false },
      clearUndefined: { type: Boolean, default: false },
      clearValue: { default: null },
      color: { type: String, default: '' },
      customSelect: { type: Boolean, default: false },
      customSelectField: String,
      deletableChips: { type: Boolean, default: false },
      dense: { type: Boolean, default: false },
      didScroll: { type: Boolean, default: false },
      dirtyCheck: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      hint: String,
      inputProps: { type: Object, default: () => ({}) },
      items: { type: Array, default: () => ([]) },
      itemText: {},
      itemValue: {},
      label: { type: String, default: '' },
      mask: { type: String, default: '' },
      maskExists: { type: Boolean, default: false },
      menuProps: { type: Object },
      multiple: { type: Boolean, default: false },
      noDataText: { type: String },
      persistentHint: { type: Boolean, default: false },
      prependIcon: { default: null },
      prependTip: { type: String, default: null },
      readonly: { type: Boolean, default: false },
      regX: { type: Boolean, default: false },
      returnObject: { type: Boolean, default: false },
      reverse: { type: Boolean, default: false },
      revertTip: { type: Boolean, default: false },
      rows: { default: null },
      rules: { default: () => [] },
      smallChips: { type: Boolean, default: false },
      stop: { type: Boolean, default: false },
      tabindex: { type: String },// Vuetify clearable can prevent tab working as user expects. To fix set :tabindex="1".
      type: { type: String, default: 'vTextField'},
      value: { required: true }
    },
    data: () => ({
      focused: false,
    }),

    computed: {
      appendIcon() {
        if (this.inputProps.appendIcon) return this.inputProps.appendIcon;
        // if (!this.regX && this.clearable && this.focused && !this.readonly && this.value) return 'mdi-close-circle';
        // if (this.regX && this.clearable && !this.readonly && this.value) return 'mdi-close';//prefer default -hoverX
      },
      appendOuterIcon() {
        if (this.inputProps.appendOuterIcon) return this.inputProps.appendOuterIcon;
        if (this.dirtyCheck && this.dirty) return 'mdi-arrow-u-left-top';
      },
      dirty() {// if value and cleanValue are not equal, field is dirty
        if (!this.dirtyCheck) return false;
        if (this.maskExists) {// if there is a mask, remove it before comparing to cleanValue
          let fieldWithAnyMaskRemoved = this.value ? this.value.replace(/\D/g, '') : this.value;
          return !this.$_eq(fieldWithAnyMaskRemoved, this.cleanValue);
        }
        // allowStr allows looser comparison  1 = "1", 0 = '0', 5.23 = '5.23', '' = null, etc. (but not 0 = '0.00', etc)
        if (this.allowStr) return !this.$_eq(this.value, this.cleanValue);
        return !(this.value === this.cleanValue);
      }

    },

    watch: {
      didScroll() {
        this.$refs.field.isMenuActive = false;// close menu if parent didScroll
      },
    },
    methods: {
      appendClick() {
        if (this.clearable && (this.focused || this.regX)) this.clear();
      },
      appendOuterClick() {
        if (this.dirtyCheck && this.dirty) this.revert();
      },
      clear() {
        let emitValue = this.clearUndefined ? undefined : this.clearValue;
        // default clearable functionality emits null, so to emit desired value after that, need delay - use setTimeout
        setTimeout(() => { if (!this.readonly && !this.disabled) this.$emit('input', emitValue) }, 10);//
      },
      enterPress(event) {
        if (this.type !== 'VTextarea') event.preventDefault();
        setTimeout(()=>{ this.$refs.field.isMenuActive = false }, 10);// need delay to close select @enter
        this.$emit('enter')
      },
      raiseClickEvent() {
        if (this.stop) event.stopPropagation()// stop propagation if stop === true
        this.$emit('click');
      },
      revert() {
        this.$emit('input', this.cleanValue);
        this.$emit('revert');
      }
    }

  }
</script>

<style scoped>
</style>
<template>
  <div>
    <v-card :key="$_sideMenu" eager style="border-top: 1px solid white !important;"
            class="px-0 pt-0 my-0 d-flex flex-row justify-space-around"
            color="primary" v-sticky="!dialog" sticky-z-index="9" sticky-offset="$_offset" tile
            large dark block>
      <div>
        <!--    transparent wrapper button to inform user with missing field dialog when SAVE button is disabled  -->
        <v-btn class="pa-0 ma-0" @click="missingFieldDialog = missingField" text tile x-large>
          <v-btn class="px-1 my-n1" @click="save" :disabled="missingField" text tile x-large>Save Firm</v-btn>
        </v-btn>
      </div>
      <div v-if="dialog"><v-btn class="px-1 my-0" x-large tile text @click="$emit('close')">Close</v-btn></div>
    </v-card>
    <v-card-text class="pa-0 ma-0" height="740">
      <v-row dense class="mx-1 mt-1 mb-1 higher" justify="center">
        <v-col cols="12" sm="4">
          <v-card class="pb-0">
            <v-card-title class="pt-1 pb-3"> Firm </v-card-title>
            <v-card-text class="pb-0"><!-- REQUIRED fields: type, status, sort, insured, producer  -->
              <ListViewer class="pb-1" v-model="firm.type" title listname="ftype" nameonly clearable
                          sorttable="short" dense :menuprops="{ maxHeight: 260 }"
                          label="Firm Type" listtitle="Firm Type" reqrules :rule="rules" sort="0"/>
              <v-row class="my-n3">
                <v-col cols="6">
                  <ListViewer class="pb-1" v-model="firm.status" title listname="fstatus" dense label="Status"
                              clearable sorttable="short" :menuprops="{ maxHeight: 210 }"
                              nameonly listtitle="Firm Status" reqrules :rule="rules" sort="0"/>
                </v-col>
                <v-col cols="6">
                  <ListViewer class="pb-1" v-model="firm.source" title listname="source" dense label="Source"
                              clearable sorttable="short" :menuprops="{ maxHeight: 210 }"
                              nameonly listtitle="Firm Source"/>
                </v-col>
              </v-row>
               <v-text-field class="pb-1 caption" v-model="firm.contact" dense
                            spellcheck="false" label="Contact Name"/>
               <v-text-field class="pb-1 caption" v-model="firm.name1" dense
                            spellcheck="false" label="Name 1"/>
               <v-text-field class="pb-1 caption" v-model="firm.name2" dense
                            spellcheck="false" label="Name 2"/>
               <v-text-field class="pb-1 caption" v-model="firm.sort" dense :rules="rules"
                            spellcheck="false" label="Sort"/>
               <v-text-field class="pb-1 caption" v-model="firm.insured" dense :rules="rules"
                            spellcheck="false" label="Legal Name"/>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card>
            <v-card-title class="pt-1 pb-3"> Numbers/Reps </v-card-title>
            <v-card-text class="pb-0">
              <ListViewer class="pb-1" dense v-model="firm.entity" title listname="entity" clearable sorttable="short"
                          label="Entity" nameonly listtitle="Entity" sort="0"/>
              <v-text-field class="pb-1" dense v-model="firm.fein"  label="FEIN" />
              <v-text-field class="pb-1" dense v-mask="'(###) ###-####'" v-model="firm.phone" label="Phone"/>
              <v-text-field class="pb-1" dense v-mask="'(###) ###-####'" v-model="firm.fax" label="Fax"/>
              <ListViewer class="pb-1" dense v-model="firm.producer" title listname="name" reqrules :rule="rules"
                          clearable sorttable="short" :menuprops="{ maxHeight: 200 }"
                          label="Producer" nameonly listtitle="Producer" sort="1"/>
              <ListViewer class="pb-1" dense v-model="firm.solicitor" title spellcheck="false" listname="name"
                          clearable sorttable="short" :menuprops="{ maxHeight: 250 }"
                          label="Solicitor" nameonly listtitle="Sub" sort="1"/>
              <ListViewer class="pb-1" dense v-model="firm.agent" title spellcheck="false" listname="name"
                          clearable sorttable="short" :menuprops="{ maxHeight: 250 }"
                          label="Agent" nameonly listtitle="Agent" sort="1"/>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" sm="4">
          <v-card>
            <v-card-title class="pt-1 pb-3"> Addresses </v-card-title>
            <v-card-text class="pb-0">
              <v-text-field class="pb-1" dense v-model="firm.address1" label="Address Line 1"/>
              <v-text-field class="pb-1" dense v-model="firm.address2" label="Address Line 2"/>
              <v-text-field class="pb-1" dense v-model="firm.city"     label="City"/>
              <ListViewer class="pb-1" v-model="firm.state" listname="states" nameonly dense
                          clearable sorttable="short" :menuprops="{ maxHeight: 200 }"
                          listtitle="State" title/>
              <v-text-field class="pb-1" dense v-model="firm.zip"     label="Zip Code"/>
              <v-text-field class="pb-1" dense v-model="firm.website" label="Website"/>
              <v-text-field class="pb-1" dense v-model="firm.email"   label="Email"/>
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>
      <v-row dense justify="center">
        <v-col cols="12">
          <v-card :key="resetNoteEditor" class="px-4 pb-3 mx-4 mt-1">
            <v-row justify="end" class="mr-4 mb-n3">
              <v-btn v-if="firm.noteHtml" text x-large color="secondary darken-2"
                     class="pb-0" @click="revertNoteToPlainText">
                EDIT
              </v-btn>
              <v-btn v-else text x-large color="secondary darken-2" @click="convertNoteToHtml">
                Render HTML
              </v-btn>
              <information-dialog-icon confirmClass="mr-3 mb-3 title" confirmColor="secondary darken-1" icon-class="mt-2"
                                       title="Rendering Firm Note HTML" tclass="headline grey lighten-2 mb-8">
                "RENDER HTML" will show how the note looks with the HTML rendered, but the note will
                not be editable in this mode. To edit the note, click "EDIT". To save
                changes, click "SAVE FIRM" above.
              </information-dialog-icon>
            </v-row>
            <div v-if="!firm.note" color="secondary darken-2"
                 class="fill-height font-italic pl-4"
                 @click="revertNoteToPlainText"> Type below to enter a note for this firm...</div>
            <div v-if="firm.noteHtml" class="mt-n3 scroll fill-height" style="height: 248px" v-html="htmlNote"/>
            <v-textarea v-else dense rows="11"
                        class="pb-0 mx-1 mt-n2 mb-n4" v-model="firm.note">
            </v-textarea>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <!--  Dialogs  -->
    <ActionDialog :value="missingFieldDialog" @input="$emit('input', $event)" max-width="700"
                  title="Missing Field/s" confirm-text="Ok" confirm-color="red lighten-2"
                  :second-button="false" @confirm="missingFieldDialog = false">
      <v-divider color="red"/>
      Please be sure the <strong>Firm Type, Firm Status, Sort, Legal Name (insured), and Producer</strong> fields are filled.
    </ActionDialog>

    <v-snackbar app color="secondary" v-model="successSnackbar" style="top: 40px;" top>
      Firm changes saved.
      <v-btn class="ml-12 pl-12" text @click="successSnackbar = false">Close</v-btn>
    </v-snackbar>

  </div>
</template>

<script>
  import ActionDialog from '../../ActionDialog';
  import { FirmsAPIService } from '@/servicehandlers/FirmsAPIService';
  import InformationDialogIcon from "../../InformationDialogIcon";
  import ListViewer from '@/components/ListViewer';

  const apiService = new FirmsAPIService();

  export default {
    name: 'EditFirm',
    components: { ActionDialog, InformationDialogIcon, ListViewer },
    props: {
      id: [String, Number],
      dialog: Boolean,
      default() {
        return {};
      }
    },
    data() {
      return {
        firm: {},
        missingFieldDialog: false,
        resetNoteEditor: false,
        rules: [(v) => !!v || 'Field is required'],
        successSnackbar: false,
      };
    },

    computed: {
      htmlNote() {
        if (!this.firm.note) return;
        return this.firm.note.replace(/(?:\r\n|\r|\n)/g, '<br>');
      },

      missingField() {
        const missing = (// REQUIRED fields: type, status, sort, insured, producer
               !this.firm.type
            || !this.firm.status
            || !this.firm.sort
            || !this.firm.insured
            || !this.firm.producer
        );
        return missing;
      },
    },

    created() {
      apiService.getSingleFirm(this.id, this.$router)
          .then((firm) => {
            this.firm = firm;
            document.title = `${firm.fid}  ${firm.sort} - ${firm.producer}/${firm.solicitor}/${firm.agent}`;
            // format phone numbers
            this.firm.phone = this.firm.phone.replace(/(\d{3})(\d{3})(\d{4})/,'($1) $2-$3');
            this.firm.fax = this.firm.fax.replace(/(\d{3})(\d{3})(\d{4})/,'($1) $2-$3');
          })
      this.firm.noteHtml = true;

      // get Items for firm editing w listname, sort, nameonly, longonly, sortorder
      let lists = this._.uniqBy(this.firmInLineFields, 'list');// only call each api once
      for (let list of lists) {
        if (list.val !== 'salutation') {// salutation field is not a dropdown
          listsAPIService.getList(list.list, list.sort, true, false,
            'ASC', 'short', null, this.$router).then((data) => {
            if (data) {
              if (list.val === 'type') {//remove blank lines from type dropdown
                for (let i = 0; i < data.length; i += 1) {
                  if (!data[i].text || !data[i].text===" " || !data[i].text.replace(/\s/g, '').length) {
                    data.splice(i, 1);
                    i = i-1;
                  }
                }
              }
              if (list.list === 'name') {// these all use same list
                this.firmItems['producer'] = data;
                this.firmItems['solicitor'] = data;
                this.firmItems['agent'] = data;
              } else {
                this.firmItems[list.val] = data;
              }
              this.refresh = !this.refresh;
            }
          });
        }
      }
    },

    methods: {
      convertNoteToHtml() {
        this.firm.noteHtml = true;
        this.resetNoteEditor = !this.resetNoteEditor;
      },

      revertNoteToPlainText() {
        this.firm.noteHtml = false;
        this.resetNoteEditor = !this.resetNoteEditor;
      },

      save() {
        if (this.firm.policies) delete this.firm.policies;
        let firmToSave =  _.cloneDeep(this.firm);
        //set all undefined properties to null, or they won't be emptied
        Object.entries(firmToSave).forEach(([key, value]) => {
          if (value === undefined) firmToSave[key] = null;
        });
        if (firmToSave.phone) firmToSave.phone = firmToSave.phone.replace(/[^\d]/g, '');
        if (firmToSave.fax) firmToSave.fax = firmToSave.fax.replace(/[^\d]/g, '');
        return apiService.updateFirm(firmToSave.fid, firmToSave, this.$router)
          .then((data) => {
            this.successSnackbar = true;
            this.$emit('refresh');
          })
          .catch((error) => {
            console.debug('ERROR: ', error);
          });
      },
    }
  };
</script>
<style>
.higher .v-text-field__details {/* shift text field (ex: 'Field is required') message higher so it fits better */
  margin: -3px 0 3px;
}
</style>

<template>
  <div>
    <v-card class="px-1 elevation-0 rounded-0 borderless" :key="$_sideMenu">
      <v-card class="d-flex flex-row elevation-6 justify-space-around mt-0 mx-0 pa-0"
              tile block style="border-top: 1px solid white !important"
              v-sticky sticky-z-index="99" sticky-offset="$_offset">
          <v-btn class="px-xs-0 px-sm-12 py-0"
                 @click="seeMoreOptions = false, seeMoreOptionsBelow = false, viewJobs(false, usernamesToShowJobsFor)"
                 small text tile>
            <span v-if="$vuetify.breakpoint.name !== 'xs'">View&nbsp</span>Jobs
          </v-btn>

          <v-dialog style="background-color: white; z-index: 999" v-model="selectUsers" max-width="500">
            <v-card>
              <v-card-actions class="pt-4 pr-3">
                Users' active/extreme jobs
                <v-spacer></v-spacer>
                <v-btn @click="viewJobs(false, names)" color="secondary" :disabled="names.length === 0">Show Jobs
                </v-btn>
                <v-btn color="secondary darken-3" icon @click="selectUsers = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-actions>
              <br><br>
              <v-card-text class="pl-5 pr-12">
                <smart-input
                    chips
                    clearable
                    :clear-value="[]"
                    deletable-chips
                    :item-text="item =>`${item.staff} ${item.activejobs}/${item.extremejobs}`"
                    :item-value="'staff'"
                    :items="userJobCounts"
                    label="Select users to show jobs for:"
                    multiple
                    small-chips
                    :type="'VAutocomplete'"
                    v-model="names"/>
              </v-card-text>
              <br>
            </v-card>
          </v-dialog>

        <div style="width: calc(100px +  20vw); margin-top: -7px; margin-bottom: 3px">
          <v-text-field
              class="smalltext px-3"
                        @click="searchByFid = true, unisearch = ''"
                        clearable
                        dense
                        hide-details
                        @keyup.enter="searchFirms()"
                        label="Get by FID"
                        single-line
                        v-model="whereColumns.fid"
          ></v-text-field>
        </div>
        <div>
          <button-tip btnclass="pa-0 ma-n1" @click="firmSearchInTab()" icolor="black"
                      icon iname="mdi-arrow-top-right" tip="Show Search Options in New Tab"/>
          <button-tip btnclass="px-xs-0 px-sm-12 py-0" small text tile
                      hideSmDown="Search " txt=" Firms" @click="searchFirmsButton()"
                      :tip="state === 'showFirms' || state === 'loadingFirms'
                       ? 'To see firms, enter a FID or unisearch (2-char min) then hit return'
                       : 'To see firms, enter a FID & press return or click SEARCH FIRMS, enter unisearch (2-char min) then hit return'"/>
        </div>
      </v-card>

      <firms-options
          :checkedProp="checkedColumns"
          @clear="clearOptions()"
          :info="info"
          @search="searchByFid = false, searchFirms()"
          @update="update"
          v-if="seeMoreOptions"
          :whereProp="whereColumns"
      ></firms-options>

      <v-expansion-panels readonly v-model="panels" multiple class="my-0 py-0 rounded-0" v-show="panels.length > 0">
        <v-expansion-panel class="px-0 elevation-24">
          <v-expansion-panel-header color="h5 secondary--text darken-3 font-weight-medium"
                                    class="dense pl-0 pr-4 py-0 my-n1 heading-2" disabled hide-actions id="plain">
            <v-spacer/>
            <v-row class="mt-n2 mb-n1" v-if="state === 'loadingJobs'  || state === 'showJobs'">
              <button-tip btnclass="py-0 ml-n3 mt-n3 mb-0"  @click="refreshJobs()" icon iname="mdi-update"
                          tip="Refresh Jobs"/>
              <button-tip btnclass="py-0 ml-n2 mt-n3 mb-0" @click="viewJobs(false, [username])" icon
                          iname="mdi-account" tip="My Jobs" v-if="$_can('job-override')"/>
              <span class="mr-1" :key="refreshJobCount">
                {{ jobs.length > 0 ? jobs.length.toLocaleString('en', {useGrouping:true}) : ''}}</span>
              <span v-if="$_can('job-override')">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div @click="selectUsers = true" v-on="on">Jobs: &nbsp</div>
                  </template>
                  Click 'Jobs:' to select users to see jobs for
                </v-tooltip>
              </span>
              <span v-else>Jobs: &nbsp</span>
              <span v-if="$vuetify.breakpoint.name !== 'xs'">
                <v-tooltip bottom :key="refreshUsernames"
                           v-if="usernamesToShowJobsFor && usernamesToShowJobsFor.toString().length > spaceForUsernames">
                  <template v-slot:activator="{ on }">
                    <div class="caption" style="margin-top: -2px; color: black" v-on="on">
                      {{ usernamesToShowJobsFor ? usernamesToShowJobsFor.toString().slice(0, spaceForUsernames-5)
                      .split(',').join(', ') + '...' : '' }}</div>
                  </template>
                  {{ usernamesToShowJobsFor.toString().split(',').join(', ') || '' }}
                </v-tooltip>
                <div style="color: black" v-else>{{ usernamesToShowJobsFor.toString().split(',').join(', ') || '' }}</div>
              </span>
            </v-row>
            <span class="py-0 mt-n4 mb-n1 mx-0" style="width: 150px" v-if="state === 'loadingFirms' || state === 'showFirms'">
              <button-tip btnclass="py-0 mt-n3 mb-n2 mx-n1" icon iname="mdi-update" tip="Refresh Firms" @click="refreshFirms()"/>
              Firms</span>
            <information-dialog-icon confirmClass="mr-3 mb-1 title" confirmColor="secondary darken-1"
              icon-class="mt-n2" title="Jobs Table Information" tclass="headline grey lighten-2 mb-8"
              v-if="state === 'loadingJobs' || state === 'showJobs'">
                <p>This table shows your jobs and also the jobs for groups that you are an allowed user for (ACLs).</p>
                <p v-if="$_can('job-override')">To see other users' jobs, click 'Jobs:', then in the dialog
                  dropdown select username(s) to show jobs for, then click 'SHOW JOBS'. Jobs shown will be just for the
                  selected username(s), not ACLs. Click <v-icon>mdi-account</v-icon>to return to viewing your jobs.</p>
                <p v-if="$_can('job-override')">Next to the usernames in the jobs dialog dropdown will be the
                  number of active/extreme jobs.</p>
                Drag jobs column headers to change the order of the columns.
            </information-dialog-icon>
            <v-menu allow-overflow :close-on-content-click="false" :open-on-click="false" v-model="seeMoreOptionsBelow" offset-y
                    nudge-top="9" nudge-width="200" style="max-height: 700px; width: calc(80vw + 200px)"
                    v-if="state === 'loadingFirms' || state === 'showFirms'">
              <template v-slot:activator="{ on }">
                <v-btn
                    class="text-none px-0"
                    v-on="on"
                    elevation="0"
                    style="background-color: white"
                >
                <v-text-field
                              append-icon="mdi-magnify" clearable style="width: 75vw; margin-right: 20px"
                              @click.prevent="whereColumns.fid = '', seeMoreOptions = false, seeMoreOptionsBelow = false"
                              v-model="unisearch"
                              hide-details single-line
                              @keyup.enter="unisearchFirms()"
                              label="Unisearch sort, name, phone, contact, insured"
                              class="pl-0 pr-2 mt-n12 mb-n7 mr-2 font-weight-regular"
                >
                  <template v-slot:prepend>
                    <information-dialog-icon
                        confirmClass="ml-1 mr-2 mb-3 title" confirmColor="secondary darken-1" icon-class="mt-n1 ml-2 mr-n2"
                        title="Firms Search Options" tclass="headline grey lighten-2 mb-8">
                      <p>Click <v-icon>mdi-arrow-down</v-icon> for options below.</p>
                      <p>Click <v-icon>mdi-close</v-icon>to close options.</p>
                      <p>Click <v-icon>mdi-arrow-up</v-icon> for options above.</p>
                      <p>Click <v-icon>mdi-arrow-top-right</v-icon> for options in a new tab.</p>
                      Table columns will appear in the order checked.
                      To change the order, you can uncheck the boxes (except FID) and then check them
                      in the order you want. Fields with an arrow allow you to search in and select from
                      a list. Fields with no arrow allow you to enter full or partial data.
                      <br><br>
                      <b>Press ENTER in any field to SUBMIT firms search.</b>
                      <br><br>
                      <p><b>Click UNISEARCH field to CLOSE search options.</b></p>
                    </information-dialog-icon>
                  </template>
                  <template v-slot:prepend-inner>
                    <button-tip btnclass="pa-0 ma-n1" @click="seeMoreOptionsBelow = false" icolor="black"
                                icon iname="mdi-close" tip="Close Search Options Below" v-if="seeMoreOptionsBelow"/>
                    <button-tip btnclass="pa-0 ma-n1" @click="seeMoreOptionsBelow = true, seeMoreOptions = false" icolor="black"
                                icon iname="mdi-arrow-down" tip="Show Search Options Below" v-if="!seeMoreOptionsBelow"/>
                    <button-tip btnclass="pa-0 ma-n1" @click="seeMoreOptions = false" icolor="black"
                                icon iname="mdi-close" tip="Close Search Options Above" v-if="seeMoreOptions"/>
                    <button-tip btnclass="pa-0 ma-n1" @click="seeMoreOptions = true, seeMoreOptionsBelow = false" icolor="black"
                                icon iname="mdi-arrow-up" tip="Show Search Options Above" v-if="!seeMoreOptions"/>
                    <button-tip btnclass="pa-0 ma-n1" @click="firmSearchInTab()" icolor="black"
                                icon iname="mdi-arrow-top-right" tip="Show Search Options in New Tab"/>
                  </template>
                </v-text-field>
              </v-btn>

              </template>
                <firms-options
                    :checkedProp="checkedColumns"
                    @clear="clearOptions()"
                    :info="info"
                    @search="searchByFid = false, searchFirms()"
                    @update="update"
                    :whereProp="whereColumns"
                ></firms-options>
            </v-menu>

            <v-text-field v-if="state === 'loadingJobs' || state === 'showJobs'"
                          v-model="search" @click.stop @keydown.enter.prevent
                          append-icon="mdi-magnify" clearable single-line
                          label="Search Jobs" style="width: 30vw"  hide-details
                          class="mt-n12 mb-n7 ml-1 mr-1 font-weight-regular">
            </v-text-field>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="noPadding">
            <v-data-table v-if="state === 'loadingJobs' || state === 'showJobs'"
                          :loading="state === 'loadingJobs'"
                          class="jobsTable elevation-2 mt-n3 mb-2 pb-0"
                          :dense="dense"
                          :search="search"
                          v-sortable-table="{onEnd:sortTheHeadersAndUpdateTheKey}"
                          :key="anIncreasingNumber"
                          loading-text="Loading... Please wait"
                          :headers="headers"
                          fixed-header
                          :mobile-breakpoint="0"
                          :sort-by="['staff', 'status', '_lag', 'cstamp']"
                          :sort-desc="[false, true, false, false]"
                          :style="$_cssVars"
                          multi-sort
                          :items="jobs"
                          :items-per-page="-1"
                          hide-default-footer>
              <template v-slot:item.actions="{ item }">
                <v-lazy>
                  <div>
                    <button-tip @click="openJobFirmInPopup(item)" icon iname="mdi-open-in-new" small tip="Open Popup"/>
                    <button-tip @click="openJobFirmBelow(item)" icon iname="mdi-eye-outline" small tip="Open Below"/>
                    <button-tip @click="markNoteDone(item)" :disabled="item.staff !== username" icon
                                iname="mdi-checkbox-blank-outline" small tip="Mark Done (removes from job list)"/>
                  </div>
                </v-lazy>
              </template>
              <template v-slot:item.firm_sort="{ item }">
                <v-tooltip left v-if="item.firm_sort && item.firm_sort.length > 30">
                  <template v-slot:activator="{ on }">
                    <div v-on="on">{{ item.firm_sort ? item.firm_sort.slice(0, 25) + '...' : '' }}</div>
                  </template>
                  {{ item.firm_sort || '' }}
                </v-tooltip>
                <div v-else>{{ item.firm_sort || '' }}</div>
              </template>
              <template v-slot:item.status="{ item }">
                <div :style="{color: (item.status === 'xtreme' ? 'red'
                  : (item.status === 'urgent' ? 'blue' : 'black'))}">
                  {{ item.status }}
                </div>
              </template>
              <template v-slot:item._lag="{ item }">
                <div :style="{color: (item.status === 'xtreme' ? 'red'
                  : (item.status === 'urgent' ? 'blue' : 'black'))}">
                  {{ item._lag }}
                </div>
              </template>
              <template v-slot:item.re="{ item }">
                <div v-html="item.re"></div>
              </template>
              <template v-slot:item.cstamp="{ item }">
                {{ moment(item.cstamp, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format('DD MMM YYYY hh:mm A')}}
              </template>
            </v-data-table>
            <view-firms-table ref="viewFirmsTable"
                              :firms="firms"
                              :checked-columns="checkedColumns"
                              :dense="dense" :total-records="totalRecords"
                              :is-unisearch="isUnisearch"
                              :edit-firm-rights="$_can('firm-edit')"
                              :state="state"
                              :styleProp="$_cssVars"
                              @pop-up="firmPopup"
                              @view-firm="seeMoreOptions = false, notPopup = true, viewFirmSearch(...arguments)"
                              @search="searchFirmsNow"></view-firms-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

    </v-card>

    <v-snackbar app color="error" v-model="overFirmLimit" style="top: 80px;" top>
      Reached 100 firm limit! Please narrow your search.
      <v-btn color="white" @click="overFirmLimit = false" text>Close</v-btn>
    </v-snackbar>

    <v-snackbar app color="error" v-model="noResults" style="top: 80px;" top>
      No search results. Search was too narrow.
      <v-btn color="white" @click="noResults = false" text>Close</v-btn>
    </v-snackbar>

    <v-snackbar app color="error" v-model="emptyFields" style="top: 80px;" top>
      At least one search field must be filled to run a search.
      <v-btn color="white" @click="emptyFields = false" text>Close</v-btn>
    </v-snackbar>

    <ErrorDialog ref="errorDialog"/>
    <view-firm  v-if="showFirmTabs"
                :noNote="noNote"
                :showFirm="showFirm"
                notPopup
                :id="id"
    />
  </div>
</template>

<script>
  // Add back the sortHandle class if it gets stripped away by external code
  function watchClass(targetNode, classToWatch) {
    // watchClass(targetNode, classToWatch) {
    let lastClassState = targetNode.classList.contains(classToWatch);
    const observer = new MutationObserver((mutationsList) => {
      for (let i = 0; i < mutationsList.length; i++) {
        const mutation = mutationsList[i];
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const currentClassState = mutation.target.classList.contains(classToWatch);
          if (lastClassState !== currentClassState) {
            lastClassState = currentClassState;
            if (!currentClassState) {
              mutation.target.classList.add('sortHandle');
            }
          }
        }
      }
    });
    observer.observe(targetNode, { attributes: true });
  }

  import ErrorDialog from "../../ErrorDialog";
  import FirmsOptions from "./FirmsOptions";
  import InformationDialogIcon from "../../InformationDialogIcon";
  import ListViewer from "../../ListViewer";
  import Sortable from 'sortablejs';
  import ViewFirm from "@/components/search/firms/ViewFirm";
  import ViewFirmsTable from "./firmsSearch/ViewFirmsTable";
  import { CommonAPIService } from '@/servicehandlers/CommonHandler';
  import { FirmsAPIService } from '@/servicehandlers/FirmsAPIService';
  import { GroupsAPIService } from '@/servicehandlers/GroupsAPIService';
  import { NotesAPIService } from '@/servicehandlers/NotesAPIService';
  import { UsersAPIService } from '@/servicehandlers/UsersAPIService';

  const apiService = new CommonAPIService();
  const firmAPIService = new FirmsAPIService();
  const groupsAPIService = new GroupsAPIService();
  const note = new NotesAPIService();
  const usersAPIService = new UsersAPIService();

  export default {
    name: 'SearchFirms',
    components: { ViewFirmsTable, ErrorDialog, FirmsOptions, InformationDialogIcon, ListViewer, ViewFirm },
    data() {
      return {
        anIncreasingNumber: 1,
        checkedColumns: ['fid', 'sort', 'phone', 'state' ],
        clear: false,
        colHeaders: [], // for firms
        dense: true, // table density
        emptyFields: false,
        filter: null,
        firm: {},
        firmArray: [], // array of firms to view
        firms: [],
        newSearch: false,
        fullFieldData: [],
        groups: [],
        headers: [], // for jobs/notes
        id: 0,
        includeCheckColumns: {}, // columns that are specific to include boxes, but with "where"
        includeColumns: [], // columns that are specific to the include checkboxes
        info: [], // info from FirmsSearchOptions
        infoDialog: false,
        isSorting: false,
        isUnisearch: false,
        jobHeaders: [ // for jobs/notes
          {value: 'actions',  text: 'Actions',  width: '130px', sortable: false, align: 'center'},
          {value: 'firm_sort',text: 'Firm Name',width: '235px'},
          {value: '_exdate',  text: 'Exdate',   width: '103px'},
          {value: 'status',   text: 'Status',   width: '110px'},
          {value: '_lag',     text: 'Age',      width: '90px'},
          {value: 'type',     text: 'Type',     width: '90px'},
          {value: 're',       text: 'Re',       width: '450px'},
          {value: 'cstamp',   text: 'Dated',    width: '180px'},
          {value: 'staff',    text: 'For',      width: '90px'},
          {value: 'closer',   text: 'Last',     width: '72px'},
          {value: 'opener',   text: 'By',       width: '68px'},
        ],
        jobs: [],
        loading: false,
        loadingJob: false,
        loggedIn: false,
        nextJobNid: 0,
        nextJobFid: 0,
        names: '',
        nid: 0,
        noNote: true,
        noResults: false,
        noteData: undefined,
        notesOpen: false, //true if returning from email/note edit page
        notPopup: true,
        overFirmLimit: false,
        panels: [],
        refresh: false,
        refreshUsernames: false,
        refreshJobCount: false,
        savedSearch: false,
        savedSearchItems: {},
        search: '', // for jobs/notes
        searchByFid: false,
        searchItems: {},
        seeMoreOptions: false,
        seeMoreOptionsBelow: false,
        selectUsers: false,
        state: 'loadingJobs',
        switchToFirms: false,
        showedit: true,
        showFirm: true,
        showFirmTabs: false,
        sortBy: null,
        sortDesc: false,
        spaceForUsernames: (window.innerWidth / 12) - 20,
        tab: null,
        totalPages: 0, // for pagination
        totalRecords: 0, // for pagination
        unisearch: '',
        user: '', // current user's username
        userId: 0,
        userJobCounts: [],//usernames with job counts
        username: '',// username from login
        usernamesToShowJobsFor: '',// tracks which username(s) is for current jobs list
        usernames: [],
        userPrefs: {},
        whereColumns: {},// columns that are specific to the where clause
      };
    },

    watch: {
      seeMoreOptions() {
        if (this.seeMoreOptions) {
          this.state = 'showFirms';
          this.panels = [0];
        }
      },
    },

    created() {
      document.title = 'Firms';
      window.addEventListener("resize", this.handleResize);
    },

    destroyed() {
      window.removeEventListener("resize", this.handleResize);
    },

    mounted() {
      this.username = this.$store.getters.username;// get username of current user
      this.userId = this.$store.getters.userId;
      this.seeMoreOptions = this.$store.getters.seeMoreFirmsOptions;//flag to open firms search options
      this.$store.dispatch('SET_FIRMS_SEARCH_OPEN', false);
      this.groups = this.$store.getters.groups;
      this.$store.getters.userPreferences.jobHeaders ? this.headers
        = this.$store.getters.userPreferences.jobHeaders : this.headers = this.jobHeaders ;
      this.$store.getters.userPreferences.dense ? this.dense
        = this.$store.getters.userPreferences.dense : false;
      this.noteData = this.$store.getters.noteData;
      this.nextJobFid = this.noteData.nextJobFid ? this.noteData.nextJobFid : 0;
      this.nextJobNid = this.noteData.nextJobNid ? this.noteData.nextJobNid : 0;
      this.usernamesToShowJobsFor = [this.username];// initially get jobs for current user
      this.viewJobs(true, this.usernamesToShowJobsFor);
      if (this.$_can('job-override')) {// if scope, can view jobs of all users, else only user's own jobs withACLs
        // get list of usernames for dropdown
        usersAPIService.getUsernames(this.$router)
        .then((usernames) => this.usernames = usernames.map(a => a.username));
        this.getJobsCountByUsers();//get userJobCounts for display in jobs dialog username dropdown
      }
    },

    directives: {//drag table column headers
      'sortable-table': {
        inserted: (el, binding) => {
          el.querySelectorAll('th').forEach((draggableEl) => {
            // Need a class watcher because sorting v-data-table rows asc/desc removes the sortHandle class
            watchClass(draggableEl, 'sortHandle');
            draggableEl.classList.add('sortHandle');
          });
          Sortable.create(el.querySelector('tr'), binding.value ? { ...binding.value, handle: '.sortHandle' } : {});
        },
      },
    },

    methods: {
      callApi(searchItems) {
        this.isUnisearch = false;
        firmAPIService.getFirm(searchItems, this.$router).then((data) => {
          if (data['result'].length >= 100  && !this.groups.includes('admin')) {
            this.overFirmLimit = true;
          } else {
            this.firms = data['result'];
            this.totalPages = data['totalpages'];
            this.totalRecords = data['count'];
            if (this.totalRecords === 0) this.noResults = true;
            this.$store.dispatch('SET_NOTES_DATA', this.noteData);
            this.noteData = this.$store.getters.noteData;
          }
          this.state = 'showFirms';
        }).catch((error) => {
          this.$refs.errorDialog.showError({
            errorMessage: 'Error getting firms',
            forDevelopers: error
          })
          this.state = 'showFirms';// end loading animation
        });
      },

      // clean column, even though you uncheck certain items, they still might be included
      // in the where clause even though the value is empty.
      // this method cleans that up before sending to the API
      cleanColumns() {
        Object.keys(this.whereColumns).forEach(key => {
          if (this.whereColumns.hasOwnProperty(key)) {
            const value = this.whereColumns[key];
            if (!value || value.length <= 0) {
              // get rid of it
              delete this.whereColumns[key];
            }
          }
        });
      },

      clearOptions() {
        this.$nextTick(() => {
          for (let key in this.whereColumns) {
            if (key !== 'fid') delete this.whereColumns[key];
          }
          this.checkedColumns = ['fid', 'sort', 'phone', 'state' ];
        });
      },

      firmPopup(id) {//passes fid, no note selected
        let baseUrl = window.location.origin;
        let w = window.open(baseUrl +  '/viewFirmNoNote/' +  id, '_blank',
          'resizeable=yes, width=' + (1140)
          + ', left=' + (300)
          + ', height=' + (730));
      },

      firmSearchInTab () {
        this.$store.dispatch('SET_FIRMS_SEARCH_OPEN', true);
        let baseUrl = window.location;
        window.open(baseUrl, '_blank');
      },

      getJobsCountByUsers() {// get the # of activejobs/extremejobs for each user to display in jobs dialog dropdown
        note.getJobsCountByUsers(this.usernames, this.$router).then((userJobCounts) => {
          _.remove(userJobCounts, a => a.staff === this.username);// remove current username from dropdown
          this.userJobCounts = _.sortBy(userJobCounts, 'staff');// sort remaining usernames for dropdown
        })
      },
      
      handleResize(e) {
        this.spaceForUsernames = (window.innerWidth / 12) - 30;
      },

      markNoteDone(item) {// status set to done, done isn't selected by API, so job/note is no longer shown in job list
        item.status = 'done';
        note.updateNote(item.nid, item, this.$router).then((response) => {
          this.$nextTick(() => this.refreshJobCount = !this.refreshJobCount);// refresh display of job count
          }).catch((error) => {
          this.$refs.errorDialog.showError({
            errorMessage: 'Error marking note done',
            forDevelopers: error
          })
        });
      },

      nextNote: function () {
        this.loadingJob = true;
        note.getNextNote(this.userId, this.$route)
          .then((response) => {
            if (response && response[0]) {
              this.nextJobFid = response[0].fid;
              this.nextJobNid = response[0].nid;
              this.noteData.status = 'Active';
              // use nid to update note with staff=username
              this.user = this.$store.getters.username;
              let parms = {staff: this.user};
              return note.updateNote(this.nextJobNid, parms, this.$router)
                .then((response) => {
                  this.loadingJob = false;
                  this.noNote = false;//tells view component that nid is being sent
                  this.viewFirm(this.nextJobNid, false);
                })
                .catch((error) => {
                  console.debug('error: ', error);
                });
            }
          })
          .catch((error) => {
            console.debug('error: ', error);
          });
      },

      openJobFirmBelow(item) {
        this.seeMoreOptions = false;
        this.notPopup = true;
        this.viewFirmJob(item.nid);
      },

      openJobFirmInPopup(item) {
        this.notPopup = false;
        this.viewFirmJob(item.nid);
      },

      refreshFirms() {
        this.state = 'loadingFirms';
        this.isUnisearch ? this.unisearchFirms() : this.callApi(this.searchItems);
      },

      refreshJobs() {
        this.refresh = true;
        this.viewJobs(false, this.usernamesToShowJobsFor);
      },

      searchFirms() {
        this.newSearch = true;// this is a new search, not just a new page, so set page to 1 in setSearchItems()
        this.savedSearch = false;
        this.state = 'showFirms';
        this.searchFirmsNow();
      },

      searchFirmsButton() {
        this.state = 'showFirms';
        this.panels = [0];
      },

      searchFirmsNow() {
        const emptyButFid = Object.entries(this.whereColumns).every(([k, v]) => k === "fid" || v === null || v === '');
        if (emptyButFid && !this.searchByFid) {// if all fields are empty except fid and !searchByFid, alert emptyFields
          this.emptyFields = true;
          return;
        } // require narrowing of search to limit firms displayed
        this.seeMoreOptionsBelow = false;
        this.unisearch = '';
        this.panels = [0];
        this.state = 'loadingFirms';
        if (this.savedSearch === true) {
          this.savedSearch = false;
          this.callApi(this.savedSearchItems);
        } else {
          this.setSearchItems();
          this.callApi(this.searchItems);
        }
        this.cleanColumns();
      },

      setSearchItems() {
        // if not searching by fid, remove fid from search, else convert fid to integer so backend will accept
        !this.searchByFid ? delete this.whereColumns.fid : this.whereColumns.fid *=1;
        if (!this.checkedColumns.includes('fid')) this.checkedColumns.push('fid');// if unisearch removed fid, add back
        if (this.newSearch === true) this.$refs.viewFirmsTable.options.page = 1;// page=1 if newSearch, not page change
        this.searchItems = {
          page: this.$refs.viewFirmsTable.options.page,
          perPage: this.$refs.viewFirmsTable.options.itemsPerPage,
          sortBy: this.$refs.viewFirmsTable.options.sortBy[0],
          sortDesc: this.$refs.viewFirmsTable.options.sortDesc[0],
          checkedColumns: this.checkedColumns,
          whereColumns: this.searchByFid ? {'fid': this.whereColumns.fid } : this.whereColumns,
          includeCheckColumns: this.includeCheckColumns,
          includeColumns: this.includeColumns,
          simple: false,
          getall: false,
        };
        this.newSearch = false;// reset newSearch so a page or other option change won't trigger resetting page to 1
      },

      sortTheHeadersAndUpdateTheKey(evt) {//drag job headers
        const headersTmp = this.headers;
        const oldIndex = evt.oldIndex;
        const newIndex = evt.newIndex;
        if (newIndex >= headersTmp.length) {
          let k = newIndex - headersTmp.length + 1;
          while (k--) {
            headersTmp.push(undefined);
          }
        }
        headersTmp.splice(newIndex, 0, headersTmp.splice(oldIndex, 1)[0]);
        this.headers = headersTmp;
        this.anIncreasingNumber += 1;
        this.userPrefs.jobHeaders = this.headers;
        this.$store.dispatch('SET_USER_PREFERENCES', this.userPrefs);
      },

      unisearchFirms() {
        this.isUnisearch = true;
        this.searchByFid = false;
        if (this.unisearch.length < 2) return;
        this.whereColumns.fid = '';
        this.state = 'loadingFirms';
        this.checkedColumns = [ 'state', 'phone', 'contact', 'sort', 'insured', 'name1', 'name2' ];
        let params = {
          search: this.unisearch
        };
        this.seeMoreOptions = false;
        this.seeMoreOptionsBelow = false;
        this.panels = [0];
        firmAPIService.getUnisearchFirm(params, this.$router).then((data) => {
          if (data.length >= 100) this.overFirmLimit = true;
          if (data.length === 0) this.noResults = true;
          this.firms = data;
          this.totalRecords = this.firms.length;
          this.state = 'showFirms';
        }).catch((error) => {
          this.$refs.errorDialog.showError({
            errorMessage: 'An error occurred while trying to unisearch the firms. ' +
              'Please ensure the form is filled out completely and correctly. ' +
              'If the problem persists please notify the development team with a detailed ' +
              'description of the error.',
            forDevelopers: error
          })
          this.state = 'showFirms';// end loading animation
        });
      },

      update(info) {// from FirmOptions.vue
        this.checkedColumns     = info[0];
        this.whereColumns       = info[1];
      },

      viewFirm(id) {//get firm and add to firmArray
        //props/params for FirmsSearch, ViewFirm, FirmNotesTable, EditNoteEmail
        this.noteData.savedSearchItems = this.searchItems;//saved and used in firmsSearch,
        this.noteData.state = this.state; //saved in firmsSearch, used in firm notes table
        this.noteData.nextJobNid = this.nextJobNid; //saved in firmsSearch, used in firm notes table
        this.noteData.nextJobFid = this.nextJobFid; //saved in firmsSearch, used in firm notes table
        this.$store.dispatch('SET_NOTES_DATA', this.noteData);
        if (!this.notPopup) {
          let baseUrl = window.location.origin;
          let w = window.open(baseUrl +  '/viewFirmNote/' +  id, '_blank',
            'resizeable=yes, width=' + (1140)
            + ', left=' + (300)
            + ', height=' + (730));
        } else {
          this.id = id;// set id for view component - if not going to popup
          this.showFirm = !this.showFirm;// if firm was just closed & opened, trigger ViewFirm watch
          this.panels = [];
          this.showFirmTabs = true;
        }
      },

      viewFirmJob(nid) {//pass job.nid to get note and firm and nextJobFid
        this.noteData.status = 'Active';
        this.noNote = false;//tells view component that nid is being sent
        this.viewFirm(nid);
      },

      viewFirmSearch(fid) {//pass fid to get firm
        this.noteData.status = 'All';
        this.noNote = true;//tells view component that fid is being sent
        this.viewFirm(fid);
      },

      viewJobs(initial, usernames) {// get jobs for current user (this.username) or list of selected usernames
        let username = [this.username];
        this.panels = [0];
        // if not initial call and not refresh and sameUsername, just update state to display jobs, but don't call API
        if (!initial && !this.refresh && usernames === this.usernamesToShowJobsFor) {
          this.state = 'showJobs';
          this.selectUsers = false;
          return;
        }
        this.state = 'loadingJobs';
        let withACLs = true;// get jobs withACLs (jobs that user is allowed user for) for current user (this.username)
        if (!(_.isEqual(usernames, username))) withACLs = false;// unless getting jobs for multiple usernames
        note.getJobs( { usernames, job: 'users', withACLs }, this.$router).then((jobs) => {
          this.usernamesToShowJobsFor = usernames;
          this.jobs = jobs;
          this.refresh = false;
          this.state = 'showJobs';
          this.refreshUsernames = !this.refreshUsernames;// refresh to show new list of which usernames' jobs show
          this.selectUsers = false;
        })
      },

    },
  };
</script>

<style>
  .smalltext.v-text-field.v-input--dense:not(.v-text-field--outlined) input {
    font-size: 14px !important;
  }
  .custom.v-text-field>.v-input__control>.v-input__slot:before {
    margin-top: 10px;
    font-size: 0.875rem;
    font-weight: 600;
    color: black !important;
    font-family: Roboto, 'Avenir', Helvetica, Arial, sans-serif;
    border-style: none;
  }
  .custom.v-text-field>.v-input__control>.v-input__slot:after {
    border-style: none;
  }
  .theme--light.v-data-table thead tr:last-child th {
    z-index: 2 !important;
  }
  .noPadding .v-expansion-panel-content__wrap {
    padding: 0;
  }
  .v-data-table--fixed-header > .v-data-table__wrapper {
    overflow-y: auto;
    padding-bottom: 6px;/* adds space at bottom of tables so row can be seen above scrollbar */
  }
  .v-data-table {line-height: 1.2 !important;}
  .fullSizeTable .v-data-table__wrapper{height:calc(100vh - 228px) !important;}
  .firmsJobsTable .v-data-table__wrapper{height:calc(100vh - var(--offset) - 120px) !important;}
  .jobsTable .v-data-table__wrapper{height:calc(100vh - var(--offset) - 75px) !important;}
  .largeSizeTable .v-data-table__wrapper{height:calc(100vh - var(--offset) - 206px) !important;}
  .largeSizeTableNoFooter .v-data-table__wrapper{height:calc(100vh - var(--offset) - 152px) !important;}
  .largeSizePoliciesTableNoFooter .v-data-table__wrapper{height:calc(100vh - var(--offset) - 252px) !important;}
  .popupLargePoliciesTableNoFooter .v-data-table__wrapper{height:calc(100vh - var(--offset) - 190px) !important;}
  .popupLargeTable .v-data-table__wrapper{height:calc(100vh - var(--offset) - 142px) !important;}
  .popupLargeTableNoFooter .v-data-table__wrapper{height:calc(100vh - var(--offset) - 90px) !important;}
  .v-toolbar__content, .v-toolbar__extension {
    padding: 0;
  }
  theme--light.v-select .v-select__selection--comma {
    padding-bottom: 10px;
  }
  #plain .v-btn::before{
    background-color: transparent;
  }
</style>

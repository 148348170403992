<template>
  <div>
    <v-card class="px-1 elevation-0 rounded-0 borderless">
      <v-card-title>
        <span class="mr-1" v-if="state === 'loadingContacts' || state === 'showContacts'"
          >Contacts</span
        >

        <v-btn
          v-if="state === 'loadingContacts' || state === 'showContacts'"
          icon
          @click="infoDialog = true"
        >
          <v-icon>mdi-information-outline</v-icon>
        </v-btn>
        <v-dialog v-model="infoDialog" width="550">
          <v-card>
            <v-card-title class="headline lighten-2" primary-title>
              Information for this page</v-card-title
            >
            <v-card-text>
              This page lists all the contact information of business entities. Information about
              specific people associated with an entity by opening a contact in a pop up window
            </v-card-text>
            <v-card-actions
              ><v-spacer></v-spacer>
              <v-btn color="secondary" text @click="(infoDialog = false), viewContacts()"
                >Got it!</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-spacer v-if="state === 'loadingContacts' || state === 'showContacts'"></v-spacer>
        <v-spacer v-if="state === 'loadingContacts' || state === 'showContacts'"></v-spacer>
        <v-text-field
          v-if="state === 'loadingContacts' || state === 'showContacts'"
          v-model="search"
          @click.stop
          @keydown.enter.prevent
          append-icon="mdi-magnify"
          clearable
          single-line
          label="Search Contacts"
          style="width:300px"
          hide-details
          class="mt-n12 mb-n7 ml-2 mr-2 font-weight-regular"
        >
        </v-text-field>
        <v-btn x-small fab color="secondary" @click="newContactDialog = true">
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-data-table
          v-if="state === 'loadingContacts' || state === 'showContacts'"
          :loading="state === 'loadingContacts'"
          class="contactsTable elevation-2 mt-n3 mb-2 pb-0"
          :dense="dense"
          :search="search"
          scrollable
          loading-text="Loading... Please wait"
          :mobile-breakpoint="0"
          :headers="contactHeaders"
          fixed-header
          :sort-by="['sort']"
          multi-sort
          :items="contacts"
          :items-per-page="30"
          :footer-props="footerProps"
        >
          <template v-slot:item.actions="{ item }">
            <v-lazy>
              <div>
                <v-tooltip bottom style="z-index: 99" class="ml-n2 mr-n4 py-0 pl-0 pr-2">
                  <template v-slot:activator="{ on }">
                    <v-btn small icon
                      ><v-icon v-on="on" @click="contactPopup()"> mdi-open-in-new</v-icon></v-btn
                    >
                  </template>
                  <span>Open Popup</span>
                </v-tooltip>
              </div>
            </v-lazy>
          </template>
          <template v-slot:item.name="{ item }">
            <v-lazy>
              <div v-html="item.name"></div>
            </v-lazy>
          </template>
          <template v-slot:item.address="{ item }">
            <v-lazy>
              <div v-html="item.address"></div>
            </v-lazy>
          </template>
        </v-data-table>
      </v-card-text>
      <new-contact-form
        :refresh.sync="refresh"
        :new-contact-dialog.sync="newContactDialog"
        :callback="viewContacts"
        v-on:view-contacts="viewContacts()"
        v-on:input="newContactDialog = false"
      />
    </v-card>
  </div>
</template>

<script>
import { ContactAPIService } from "@/servicehandlers/ContactAPIService";
import NewContactForm from "./NewContactForm";

const contactAPIService = new ContactAPIService();

export default {
  name: "SearchContacts",
  components: { NewContactForm },
  data() {
    return {
      search: "",
      infoDialog: false,
      dense: true, //table density
      userPrefs: {},
      fullFieldData: [],
      pageRights: [],
      editFirmRights: false,
      editClaimRights: false,
      downloadRights: false,
      groups: [],
      footerProps: { "items-per-page-options": [15, 20, 30, 50, -1] },
      contactHeaders: [
        { value: "actions", text: "Actions", width: "100px", sortable: false, align: "center" },
        { value: "sort", text: "Sort Name", width: "130px" },
        { value: "name", text: "Name", width: "425px" },
        { value: "type", text: "Type", width: "100px" },
        { value: "phone", text: "Phone", width: "100px" },
        { value: "fax", text: "Fax", width: "100px" },
        { value: "address", text: "Address" }
      ],
      contacts: [],
      newContactDialog: false,
      // defaults & columns that are specific to the where clause, in table firms
      state: "loadingContacts",
      refresh: false
    };
  },

  created() {
    document.title = "Contacts";
  },

  mounted() {
    this.groups = this.$store.getters.groups;
    this.$store.getters.userPreferences.dense
      ? (this.dense = this.$store.getters.userPreferences.dense)
      : false;
    this.viewContacts();
  },

  methods: {
    contactPopup() {
      //passes fid, no note selected
      let baseUrl = window.location.origin;
      let w = window.open(
        baseUrl,
        "_blank",
        "resizeable=yes, width=" + 1100 + ", left=" + 300 + ", height=" + 700
      );
    },
      viewContacts() {
            this.state = 'loadingContacts'
            if (this.contacts.length > 0 && !this.refresh) {
                this.state = 'showContacts';
            }
            else{
                this.loading = true;
                contactAPIService.getContacts(this.$router)
                    .then((contacts) => {
                        const final = contacts.map(x => (
                            {...x,
                             name: x.name1 + (x.name2 ? '<br/>' + x.name2 : ''),
                             address: (x.address1 ?  x.address1 + '<br/>' : '')
                                        + (x.address2 ? x.address2 + '<br/>': '')
                                        + (x.city ? x.city + ', ' : '')
                                        + (x.state ? x.state : '')
                                        + (x.zip ? ' ' + x.zip : '')}))
                        console.log(final)
                        this.contacts = final;
                        this.refresh = false;
                        if (this.state === 'loadingContacts') this.state = 'showContacts'
                    })
            }
      }
    }
};
</script>

<style scoped>
button.btn:focus {
  border-width: 0;
}
button,
input,
btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}
.list-horizontal li {
  display: inline-block;
}
.list-horizontal li:before {
  content: "\00a0\2022\00a0\00a0";
  color: #999;
  color: rgba(0, 0, 0, 0.5);
  font-size: 11px;
}
/*.v-list-item--link::before { background-color: red; }*/

/*.theme--light.v-list-item:hover::before { opacity: 0.64; }*/

.list-horizontal li:first-child:before {
  content: "";
}
</style>
<style>
.custom.v-text-field > .v-input__control > .v-input__slot:before {
  margin-top: 10px;
  font-size: 0.875rem;
  font-weight: 600;
  color: black !important;
  font-family: Roboto, "Avenir", Helvetica, Arial, sans-serif;
  border-style: none;
}
.custom.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: none;
}
.theme--light.v-data-table thead tr:last-child th {
  z-index: 2 !important;
}
.noPadding .v-expansion-panel-content__wrap {
  padding: 0;
}
.v-data-table {
  line-height: 1.2 !important;
}

.v-toolbar__content,
.v-toolbar__extension {
  padding: 0;
}
theme--light.v-select .v-select__selection--comma {
  padding-bottom: 10px;
}
/*.v-list-item--link::before {*/
/*  background-color: lightgrey !important;*/
/*}*/
#plain .v-btn::before {
  background-color: transparent;
}
</style>
